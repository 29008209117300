import React from "react";
import Experience from "../sections/Experience";

function Content() {
  return (
    <main>
      <Experience />
    </main>
  );
}

export default Content;
